




















































































































































































































































































































































































































































































import Vue from "vue";
import Contact from "@/views/Home/Contact.vue";
import TruckImageGalery from "@/components/TruckImageGalery.vue";
import { ACT_TRUCK_DETAILS } from "@/store/actions/types";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import Axios from "axios";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "truck-details",
  components: { Contact, TruckImageGalery, VueHcaptcha },
  data() {
    return {
      applyDialog: false,
      dialog: false,
      model: "Trunk model",
      currentImage: 0,
      loading: true,
      error: false,
      errorMessage: "",
      name: "",
      lastname: "",
      current_address: "",
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      license: "",
      social_security: "",
      birth: "",
      phone: "",
      verified: false,
      expired: false,
      captchaKey: "",
      vehiculo: "",
      details: {
        // Example data
        active: true,
        _id: "60ae9d5f223d9100111fc8ea",
        model: [
          {
            _id: "60ae9611223d9100111fc8e7",
            active: true,
            brandId: "60ae95f1223d9100111fc8e3",
            name: "Test data",
            brand: {
              _id: "60ae95f1223d9100111fc8e3",
              active: true,
              name: "Brand 2",
            },
          },
        ],
        engineModel: [
          {
            _id: "60b8d910ebd5b800156dbe4b",
            active: true,
            manufacturerId: "60b8d906ebd5b800156dbe4a",
            name: "Model 1",
            manufacturer: {
              _id: "60b8d906ebd5b800156dbe4a",
              active: true,
              name: "Engine 1",
            },
          },
        ],
        vin: "123456dg",
        description: "",
        stock: "",
        wheelBase: "220",
        pictureUrls: [],
        mileage: "1200000",
        transmission: "FWD",
        exterior_color: "Red",
        interior_color: "Black",
        year: "2010",
        status: "IN_STORE",
        youtube: null,
      },
      expanded: [0, 1],
    };
  },
  computed: {
    mileageRed(): string {
      let result = "";
      const number = parseInt(this.details.mileage.split(" ")[0]);

      if (number) {
        if (number > 1000) {
          result = `${number / 1000}mil`;
        } else if (number > 10 ** 6) {
          result = `${number / 10 ** 6}mill`;
        } else {
          result = number.toString();
        }
      }

      return result + " <b>mi</b>";
    },
  },
  mounted() {
    const id = this.$route.params.truck;

    this.loading = true;
    this[ACT_TRUCK_DETAILS](id)
      .then((details: any): void => {
        this.details = details;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.$router.push({
          name: "Error 404",
        });
      });
  },
  methods: {
    applyNow(vin: any) {
      this.applyDialog = true;
      this.vehiculo = vin;
    },
    onVerify(token: string, captchaKey: string) {
      this.verified = true;
      this.captchaKey = captchaKey;
    },
    onExpire() {
      this.verified = false;
      this.expired = true;
    },
    closeVideo() {
      this.dialog = false;
    },
    openVideo() {
      this.dialog = true;
    },
    send() {
      if (
        this.name !== "" &&
        this.lastname !== "" &&
        this.current_address !== "" &&
        this.social_security !== "" &&
        this.phone !== ""
      ) {
        if (this.captchaKey !== "") {
          this.dialog = false;
          Axios.post("https://api.jatitrucksales.com/api/rest/v1/bookings", {
            name: this.name,
            lastname: this.lastname,
            current_address: this.current_address,
            street_address: this.street_address,
            city: this.city,
            state: this.state,
            zip_code: this.zip_code,
            license: this.license,
            social_security: this.social_security,
            birth: this.birth,
            phone: this.phone,
            hcaptcha: this.captchaKey,
            vin: this.vehiculo,
          }).then((response) => {
            if (response.status == 201) {
              this.error = false;
              this.errorMessage = "";
              this.name = "";
              this.lastname = "";
              this.current_address = "";
              this.street_address = "";
              this.city = "";
              this.state = "";
              this.zip_code = "";
              this.license = "";
              this.social_security = "";
              this.birth = "";
              this.phone = "";
              this.verified = false;
              this.expired = false;
              this.captchaKey = "";
              this.vehiculo = "";
            }

            this.applyDialog = false;
          });
        } else {
          this.errorMessage =
            "You are human? You forgot to confirm the captcha...";
          this.error = true;
          return;
        }
      } else {
        this.errorMessage =
          "You must complete all required fields before submitting the application";
        this.error = true;
      }
    },
    ...mapActions([ACT_TRUCK_DETAILS]),
  },
});







































import Vue from "vue";
import { TruckList } from "@/components/TruckList";
import { NavbarCategories } from "@/components/layout";
import MainSection from "@/components/MainSection.vue";
// import InfiniteLoading from "vue-infinite-loading";

import { pagination } from "@/mixins";
import { mapMutations, mapState } from "vuex";
import { httpClient } from "@/services";
import { MUT_TRUCKS } from "@/store/mutations/types";

export default Vue.extend({
  name: "all-trucks",
  mixins: [pagination],
  components: {
    TruckList,
    NavbarCategories,
    MainSection,
    // InfiniteLoading,
  },
  data() {
    return {
      loading: true,
      text: '',
    };
  },
  computed: {
    ...mapState(["trucks"]),
  },
  methods: {
    // infiniteHandler($state: any) {
    //   const url = `truck?${(this as any).paginationParams}`;

    //   (this as any).getTrucks($state, url);
    // },
    ...mapMutations([MUT_TRUCKS]),
  },
  mounted() {
    this.loading = true;
    httpClient.get("truck?limit=-1&skip=0").then((response) => {
      this[MUT_TRUCKS](response.data.data);
      this.loading = false;
    });
  },
});



























































import { urlMedia } from "@/enviorment";
import Vue from "vue";

export default Vue.extend({
  name: "truck-image-galery",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    truckId: String,
  },
  data() {
    return {
      currentImage: 0,
    };
  },
  computed: {
    imageList(): string[] {
      let result: string[] = [];

      this.images.forEach((image: any) => {
        const extension = image.split(".")[1];
        const extensions = "png jpg jpeg";

        if (extensions.includes(extension)) {
          result.push(`${urlMedia}camion/${this.truckId}/${image}`);
        }
      });

      return result;
    },
  },
});
